/** @jsx jsx */
import { Box, Flex, jsx, MenuButton } from "theme-ui";
import {
  useGlobalsBoolean,
  usePersist,
} from "gatsby-plugin-hfn-profile/globals";
import { Link } from "gatsby";
import { useEffect } from "react";
import PropTypes from "prop-types";
import SiteConfig from "../../data/SiteConfig";
import SideBar, { NAV } from "./SideBar";
import "../../static/styles/header.css";

const Header = ({ isDonateBtn = false }) => {
  const [showNav, { on }] = useGlobalsBoolean(NAV);
  const [, setIsMobileSite] = usePersist("isMobileSite", false);

  useEffect(() => {
    setIsMobileSite(false);
  }, [setIsMobileSite]);

  return (
    <div sx={{ borderBottom: "1px solid #c6c6c6" }}>
      <Flex sx={{ m: "12px" }}>
        <Box
          sx={{
            flex: "1 1 auto",
            display: "inline-flex",
            width: "-webkit-fill-available",
          }}
        >
          <Link to="/">
            <img
              src={SiteConfig.siteLogo}
              alt="Logo"
              height="auto"
              width="150"
            />
          </Link>
        </Box>
        <div className="d-flex justify-content-center align-items-center">
          {isDonateBtn && (
            <a href="#donateNow" className="donateNowBtn">
              Donate Now
            </a>
          )}
        </div>
        <Box
          onClick={on}
          sx={{ p: 2, "&:hover": { color: "grey", cursor: "pointer" } }}
        >
          <MenuButton aria-label="Toggle Menu" />
        </Box>
      </Flex>
      {showNav && <SideBar />}
    </div>
  );
};

Header.propTypes = {
  isDonateBtn: PropTypes.string,
};

Header.defaultProps = {
  isDonateBtn: false,
};
export default Header;
